<script>
import { mapActions } from 'vuex';
import { CREATE_GROUP } from '@/store/funnels/actions';
import editGroupDialog from '@/components/dialogs/changeGroup';

export default {
  name: 'EzfGroupCreateButton',
  data() {
    return {
      inProgress: false,
    };
  },
  methods: {
    ...mapActions('funnels', [CREATE_GROUP]),
    async onCreateGroup() {
      const group = await editGroupDialog();
      if (group) {
        this.inProgress = true;
        try {
          await this[CREATE_GROUP](group);
        } finally {
          this.inProgress = false;
        }
      }
    },
  },
};
</script>

<template>
  <ez-button
    type="primary"
    icon="plus"
    :preloader="inProgress"
    @click="onCreateGroup"
    data-test="group-create-button"
  >
    {{ $t("groups.createButton") }}
  </ez-button>
</template>
