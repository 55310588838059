import { show } from '@/services/modal.js';
import ezCloneFunnelDialog from './dialog.vue';

export const NAME = 'clone-funnel';

export default function editTemplateDialog(funnel) {
  const {
    id, name, groupId, domainId,
  } = funnel;
  const props = {
    id,
    name,
    groupId,
    domainId,
  };
  return new Promise(((resolve) => {
    show(
      ezCloneFunnelDialog,
      props,
      {
        name: NAME,
        height: 'auto',
        width: 600 /* "is-xmedium" */,
        // draggable: true,
        scrollable: true,
        classes: ['dialog', 'is-service-funnels'],
      },
      {
        'before-close': (e) => {
          resolve(e.params);
        },
      },
    );
  }));
}
