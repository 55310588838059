<script>
import { createNamespacedHelpers, mapState } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { OPTIONS } from '@/enums';
import ezLoadingBlock from '@/components/ezLoadingBlock.vue';

import * as funnelsGetters from '@/store/funnels/getters';
import * as funnelsActions from '@/store/funnels/actions';
import * as funnelsMutations from '@/store/funnels/mutations';
import * as headerMutations from '@/store/header/mutations';
// import { NAV_TABS } from "@/store/header/getters";

import cellsMixin from './mixins/panelCells';

const {
  mapMutations: headerMapMutations,
  // mapGetters: headerMapGetters
} = createNamespacedHelpers('header');
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'funnels',
);
export default {
  components: { ezLoadingBlock },
  mixins: [cellsMixin],
  // beforeRouteEnter(to, from, next) {
  //   next(vm => {
  //     console.log(vm.NAV_TABS);
  //   });
  // },
  data() {
    return {
      color: OPTIONS.GROUPS_COLOR,
      select_color: OPTIONS.SELECT_COLOR,
      isLoading: false,
    };
  },

  created() {
    this[headerMutations.SET_ACTIVE_NAV_TAB](this.$route.name);
    this[funnelsMutations.SET_PAGE_LIMIT]({ page: 1 });
    this.search = this.$route.query.search || '';
    this.loadData();
  },

  computed: {
    // ...headerMapGetters([NAV_TABS]),
    ...mapGetters([funnelsGetters.FUNNELS, funnelsGetters.PAGINATION]),
    ...mapGetters({
      pagination: funnelsGetters.PAGINATION,
      funnels: funnelsGetters.FUNNELS,
    }),
    ...mapGetters({
      count: funnelsGetters.ARCHIVE_FUNNELS_COUNT,
    }),
    ...mapFields('funnels', ['search']),
    isPagination() {
      return this.search === '' && this.pagination.total > 1;
    },
    isFunnels() {
      return this[funnelsGetters.FUNNELS].length > 0;
    },
    selected() {
      return this[funnelsGetters.FUNNELS].filter((f) => f.checked).map((f) => f.id);
    },
    countSelected() {
      return this.selected.length;
    },
    isSelected() {
      return this.countSelected > 0;
    },
    ...mapState(['currency']),
  },

  methods: {
    ...headerMapMutations([headerMutations.SET_ACTIVE_NAV_TAB]),
    ...mapActions([funnelsActions.LOAD_ARCHIVE, funnelsActions.UPDATE_FUNNELS]),
    ...mapMutations([
      funnelsMutations.SET_CHECK_FUNNEL_ID,
      funnelsMutations.UNCHECK_FUNNELS,
      funnelsMutations.SET_PAGE_LIMIT,
      funnelsMutations.SET_PAGINATION,
    ]),
    async loadData() {
      this.isLoading = true;
      await this[funnelsActions.LOAD_ARCHIVE]();
      this.isLoading = false;
      if (this.search === '') {
        await this.handleEmptyPage('replace');
      }
      // console.log("ACHIVE FUNNELS", this[funnelsGetters.FUNNELS]);
    },
    onCheckFunnel(checked, id) {
      this[funnelsMutations.SET_CHECK_FUNNEL_ID]({ checked, id });
    },
    getColor(checked) {
      return checked ? this.select_color : (this.color || 'rgba(255, 255, 255, 0)');
    },
    onUncheckAll() {
      this[funnelsMutations.UNCHECK_FUNNELS](this.selected);
    },
    handleEmptyPage(routerMethod) {
      if (this.funnels.length === 0) {
        const { page, total } = this.pagination;
        if (page === 1 && total === 1) {
          return this.$router[routerMethod]({ name: 'funnels' });
        }
        if (total === page) {
          this[funnelsMutations.SET_PAGE_LIMIT]({
            page: Math.max(1, page - 1),
          });
        }
        return this.loadData();
      }
    },
    async onRestore(id) {
      await this[funnelsActions.UPDATE_FUNNELS]({
        ids: id || this.selected,
        values: { archive: false },
      });
      await this.handleEmptyPage('push');
      this.loadData();
    },
    onPagination(pages) {
      this[funnelsMutations.SET_PAGE_LIMIT](pages);
      this.loadData();
    },
  },

  watch: {
    search(search, prev) {
      if ((search !== '' && prev === '') || (search === '' && prev !== '')) {
        this.loadData();
      }
      const { search: searchParam = '' } = this.$route.query;
      if (search !== searchParam) {
        this.$router.replace({ query: search && { search } });
      }
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <ez-sub-header>
      <template slot="title" v-if="isSelected">
        <i18n
          path="funnelSelected.text"
          tag="div"
          :places="{ n: countSelected }"
        >
          <span>{{ $tc("funnelSelected.funnels", countSelected) }}</span>
        </i18n>
      </template>

      <template slot="title" v-else>
        <i18n path="showMe.text" tag="div">
          <span>{{ $t("funnels.tabs.archive") }}</span>
        </i18n>
      </template>

      <!--
      <template slot="filters">
        <ez-button icon="plus">{{ $t("filters.add") }}</ez-button>
      </template>
      -->

      <template slot="tags" v-if="isSelected">
        <ez-button-group justify="end">
          <ez-button outline @click="onRestore(null)">
            {{ $t("funnels.list.restoreSelected") }}
          </ez-button>
          <ez-button outline @click="onUncheckAll">
            {{ $tc("funnels.list.unselectAll", countSelected) }}
          </ez-button>
        </ez-button-group>
      </template>

      <template slot="search">
        <ez-form-item direction="end">
          <ez-input
            type="search"
            v-model="search"
            :placeholder="$tc('funnels.search.placeholder', count)"
          ></ez-input>
        </ez-form-item>
      </template>
    </ez-sub-header>

    <ez-content background>
      <ez-main>
        <ez-container>
          <ez-loading-block :loading="isLoading">
            <ez-row class="mb-4" v-if="!isFunnels">
              <ez-col>
                <div class="empty-message">
                  You don’t have arhieved funnels.
                </div>
              </ez-col>
            </ez-row>
            <ez-row class="mb-5" v-else>
              <ez-col>
                <ez-panel
                  v-for="funnel in funnels"
                  :key="funnel.id"
                  size="medium"
                  :colorArea="getColor(funnel.checked)"
                  :cells="cells(funnel)"
                >
                  <template slot="checkbox">
                    <ez-checkbox
                      :value="funnel.checked"
                      @input="onCheckFunnel($event, funnel.id)"
                    ></ez-checkbox>
                  </template>

                  <template slot="title">
                    {{ funnel.name }}
                    <div class="ez-panel__table">
                      <div class="ez-panel__table-row">
                        <div class="ez-panel__table-cell">
                          <div
                            class="ez-panel__cell-title"
                            v-t="'funnels.item.funnelType:'"
                          ></div>
                        </div>
                        <div class="ez-panel__table-cell">
                          <div
                            class="ez-panel__cell-title"
                            v-t="`funnels.types.${funnel.type}`"
                          ></div>
                        </div>
                      </div>
                      <div class="ez-panel__table-row">
                        <div class="ez-panel__table-cell">
                          <div
                            class="ez-panel__cell-title"
                            v-t="'funnels.item.lastEdited'"
                          ></div>
                        </div>
                        <div class="ez-panel__table-cell">
                          <div class="ez-panel__cell-title">
                            {{ funnel.dtUpdateAgo }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <template slot="buttons">
                    <ez-button-group>
                      <ez-button type="secondary" @click="onRestore(funnel.id)">
                        {{ $t("funnels.item.buttons.restore") }}
                      </ez-button>
                    </ez-button-group>
                  </template>
                </ez-panel>
              </ez-col>
            </ez-row>
            <ez-row v-if="isPagination">
              <ez-col>
                <ez-pagination
                  :show-limit="true"
                  :page="pagination.page"
                  :page-count="pagination.total"
                  :page-range="pagination.range"
                  :limit="pagination.limit"
                  :limit-options="pagination.options"
                  @change="onPagination"
                />
              </ez-col>
            </ez-row>
          </ez-loading-block>
        </ez-container>
      </ez-main>
    </ez-content>
  </div>
</template>
