<script>
/* eslint-disable */
import draggable from 'vuedraggable';
import { createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { OPTIONS } from '@/enums';
import EzfLoadingBlock from '@/components/ezLoadingBlock.vue';
import EzfEmpty from '@/components/empty/empty.vue';
import EditGroupDialog from '@/components/dialogs/changeGroup';
import ConfirmDelete from '@/components/dialogs/confirmDelete';
import * as funnelsGetters from '@/store/funnels/getters';
import * as funnelsActions from '@/store/funnels/actions';
import * as funnelsMutations from '@/store/funnels/mutations';
import * as headerMutations from '@/store/header/mutations';
import EzfGroupFunnels from './group-funnels.vue';

const { mapMutations: headerMapMutations } = createNamespacedHelpers('header');
const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'funnels',
);

export default {
  components: {
    EzfEmpty,
    EzfLoadingBlock,
    EzfGroupFunnels,
    EditGroupDialog,
    ConfirmDelete,
    draggable,
  },

  data() {
    return {
      isLoading: false,
      funnelMap: false,
      shareFunnel: false,
    };
  },
  computed: {
    ...mapGetters({
      FUNNELS_GROUP: funnelsGetters.FUNNELS_GROUP,
      count: funnelsGetters.FUNNELS_COUNT,
      selected: funnelsGetters.SELECTED_FUNNELS,
      countSelected: funnelsGetters.COUNT_SELECTED_FUNNELS,
      isSelected: funnelsGetters.HAS_SELECTED_FUNNELS,
    }),
    ...mapFields('funnels', ['search']),
    groups: {
      get() {
        return this.FUNNELS_GROUP;
      },
      set(value) {
        const groups = value.filter((group) => group.id !== 'ungrouped');
        this.[funnelsActions.REORDER_GROUPS](groups);
      },
    },
  },

  watch: {
    search(search) {
      const { search: searchParam = '' } = this.$route.query;
      if (search !== searchParam) {
        this.$router.replace({ query: search && { search } });
      }
    },
  },

  created() {
    this[headerMutations.SET_ACTIVE_NAV_TAB](this.$route.name);
    this.search = this.$route.query.search || '';
    this.loadData();

    window.EventBus.$on('create-group', this.toCreateGroup);
  },

  methods: {
    ...headerMapMutations([headerMutations.SET_ACTIVE_NAV_TAB]),
    ...mapActions([
      funnelsActions.LOAD_FUNNELS,
      funnelsActions.UPDATE_FUNNELS,
      funnelsActions.REORDER_GROUPS,
    ]),
    ...mapActions( {
      createGroup: funnelsActions.CREATE_GROUP,
      updateGroup: funnelsActions.UPDATE_GROUP,
      deleteGroup: funnelsActions.DELETE_GROUP
    }),
    ...mapMutations([
      funnelsMutations.TOGGLE_GROUP_OPEN,
      funnelsMutations.SET_CHECK_GROUP,
      funnelsMutations.UNCHECK_GROUPS_FUNNELS,
    ]),
    onToggleOpenGroup(id) {
      this[funnelsMutations.TOGGLE_GROUP_OPEN](id);
    },
    onCheckGroup(checked, id) {
      this[funnelsMutations.SET_CHECK_GROUP]({ checked, id });
    },
    getColor(checked, color) {
      return checked ? OPTIONS.SELECT_COLOR : (color || 'rgba(255, 255, 255, 0)');
    },
    onUncheckAll() {
      this[funnelsMutations.UNCHECK_GROUPS_FUNNELS](this.selected);
    },
    onArchive(id) {
      this[funnelsActions.UPDATE_FUNNELS]({
        ids: id || this.selected,
        values: { archive: true },
      });
    },
    async loadData() {
      this.isLoading = true;
      await this[funnelsActions.LOAD_FUNNELS]();
      this.isLoading = false;
    },
    async toEditGroup(group) {
      const result = await EditGroupDialog(group);
      if (result) {
        this.updateGroup({
          id: group.id,
          values: result,
        });
      }
    },
    async toDeleteGroup(group) {
      const isDelete = await ConfirmDelete('group', group.name);
      if (isDelete) {
        await this.deleteGroup(group.id);
      }
    },
    async toCreateGroup() {
      const result = await EditGroupDialog();
      if (result) {
        this.createGroup(result);
      }
    }
  },
  destroyed() {
    window.EventBus.$off('create-group', this.toCreateGroup);
  }
};
</script>

<template>
  <div class="content-wrapper">
    <ez-sub-header>
      <template slot="title" v-if="isSelected">
        <i18n
          path="funnelSelected.text"
          tag="div"
          :places="{ n: countSelected }"
        >
          <span>{{ $tc("funnelSelected.funnels", countSelected) }}</span>
        </i18n>
      </template>

      <template slot="title" v-else>
        <i18n path="showMe.text" tag="div">
          <span>{{ $t("showMe.funnels") }}</span>
        </i18n>
      </template>

      <!--
      <template slot="filters">
        <ez-button icon="plus">{{ $t("filters.add") }}</ez-button>
      </template>
      -->

      <template slot="tags" v-if="isSelected">
        <ez-button-group justify="end">
          <ez-button outline @click="onArchive(null)">
            {{ $t("funnels.list.archiveSelected") }}
          </ez-button>
          <ez-button outline @click="onUncheckAll">
            {{ $tc("funnels.list.unselectAll", countSelected) }}
          </ez-button>
        </ez-button-group>
      </template>

      <template slot="search">
        <ez-form-item direction="end">
          <ez-input
            type="search"
            v-model="search"
            :placeholder="$tc('funnels.search.placeholder', count)"
          ></ez-input>
        </ez-form-item>
      </template>
    </ez-sub-header>

    <ez-content background>
      <ez-main>
        <ez-container>
          <ezf-loading-block :loading="isLoading">
            <ezf-empty v-if="count === 0" type="funnels">
              <template slot="title">
                {{ $t("funnels.empty.title") }}
              </template>
              <template slot="description">
                {{ $t("funnels.empty.description") }}
              </template>
            </ezf-empty>
            <draggable
              v-model="groups"
              draggable=".is-drag"
              handle=".handle"
            >
              <ez-row
                v-for="group in groups"
                :key="group.id"
                class="mb-4 is-drag"
              >
                <ez-col>
                  <ez-collapse
                    @click="onToggleOpenGroup(group.id)"
                    :time="0.3"
                    :value="group.opened"
                    class="pointer"
                  >
                    <template slot="header">
                      <ez-panel :colorArea="getColor(group.checked, group.color)">
                        <template
                          v-if="group.id !== 'ungrouped'"
                          slot="checkbox"
                        >
                          <ez-icon
                            name="drag-dots"
                            size="12"
                            class="handle"
                            color="rgba(255, 255, 255, 0.4)"
                          />
                        </template>
                        <template slot="title">{{ group.name }}</template>
                        <template
                          v-if="group.id !== 'ungrouped'"
                          slot="control"
                        >
                          <ez-dropdown type="solid">
                            <ez-button type="text" icon="ellipsis-v" size="xsmall" />
                            <ez-dropdown-menu slot="menu" direction="right">
                              <ez-dropdown-item
                                icon="pen"
                                @click.prevent="toEditGroup(group)"
                              >
                                {{ $t("Edit") }}
                              </ez-dropdown-item>
                              <ez-dropdown-item
                                icon="trash"
                                type="danger"
                                iconColor="#ee5253"
                                @click="toDeleteGroup(group)"
                              >
                                {{ $t("Delete") }}
                              </ez-dropdown-item>
                            </ez-dropdown-menu>
                          </ez-dropdown>
                        </template>
                      </ez-panel>
                    </template>
                    <ezf-group-funnels :group="group" slot="content" />
                  </ez-collapse>
                </ez-col>
              </ez-row>
            </draggable>
          </ezf-loading-block>
        </ez-container>

        <!-- DIALOG FUNNEL MAP -->
        <ez-dialog :open.sync="funnelMap" size="xbig" icon-header="map">
          <template slot="header"
            >Funnel map</template
          >

          <div class="funnel-map">
            <div class="funnel-map-table">
              <div class="funnel-map-table__col is-first">
                <div class="funnel-map-table__cell">Funnel Core:</div>
                <div class="funnel-map-table__cell">No. of Steps</div>
              </div>
              <div class="funnel-map-table__col">
                <div class="funnel-map-table__cell">Webinar</div>
                <div class="funnel-map-table__cell">9</div>
              </div>
            </div>

            <ez-scrollable
              class="builder__section"
              type="horizontal"
              bar-position="bottom"
            >
              <div class="builder__main">
                <div class="builder__group">
                  <div class="builder__group-header builder__group-header_blue">
                    {{ $t("builder.groups.core") }}
                  </div>
                  <div class="builder__group-body">
                    <div class="event-card">
                      <div class="event-card__image"></div>
                      <div class="event-card__title-wrap">
                        <!-- eslint-disable max-len-->
                        <ez-tooltip
                          content="<b>Thank You Page</b><br>It’s the page where user get words of gratitude about his purchase."
                          placement="bottom"
                        >
                          <!-- eslint-enable max-len -->
                          <ez-icon
                            class="event-card__tooltip"
                            name="question-circle"
                            color="#3d82cf"
                            size="14"
                          ></ez-icon>
                        </ez-tooltip>
                        <div class="event-card__title">Optin Page</div>
                      </div>
                    </div>

                    <div class="event-card">
                      <div class="event-card__image"></div>
                      <div class="event-card__title-wrap">
                        <ez-icon
                          class="event-card__tooltip"
                          name="question-circle"
                          color="#3d82cf"
                          size="14"
                        ></ez-icon>
                        <div class="event-card__title">Thank You Page</div>
                      </div>
                    </div>

                    <div class="event-card">
                      <div class="event-card__image"></div>
                      <div class="event-card__title-wrap">
                        <ez-icon
                          class="event-card__tooltip"
                          name="question-circle"
                          color="#3d82cf"
                          size="14"
                        ></ez-icon>
                        <div class="event-card__title">Thank You Page</div>
                      </div>
                    </div>

                    <div class="event-card">
                      <div class="event-card__image"></div>
                      <div class="event-card__title-wrap">
                        <ez-icon
                          class="event-card__tooltip"
                          name="question-circle"
                          color="#3d82cf"
                          size="14"
                        ></ez-icon>
                        <div class="event-card__title">Thank You Page</div>
                      </div>
                    </div>

                    <div class="event-group is-active">
                      <div class="event-group__title">Webinar</div>
                      <div class="event-group__card-container">
                        <div class="event-card">
                          <div class="event-card__image"></div>
                          <div class="event-card__title-wrap">
                            <ez-icon
                              class="event-card__tooltip"
                              name="question-circle"
                              color="#3d82cf"
                              size="14"
                            ></ez-icon>
                            <div class="event-card__title">Countdown Page</div>
                          </div>
                        </div>

                        <div class="event-card">
                          <div class="event-card__image"></div>
                          <div class="event-card__title-wrap">
                            <ez-icon
                              class="event-card__tooltip"
                              name="question-circle"
                              color="#3d82cf"
                              size="14"
                            ></ez-icon>
                            <div class="event-card__title">Webinar Room</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="builder__group">
                  <div
                    class="builder__group-header builder__group-header_yellow"
                  >
                    {{ $t("builder.groups.sales") }}
                  </div>
                  <div class="builder__group-body">
                    <div class="event-group">
                      <div class="event-group__title">Webinar</div>
                      <div class="event-group__card-container">
                        <div class="event-card">
                          <div class="event-card__image"></div>
                          <div class="event-card__title-wrap">
                            <ez-icon
                              class="event-card__tooltip"
                              name="question-circle"
                              color="#3d82cf"
                              size="14"
                            ></ez-icon>
                            <div class="event-card__title">Optin Page</div>
                          </div>
                        </div>

                        <div class="event-card">
                          <div class="event-card__image"></div>
                          <div class="event-card__title-wrap">
                            <ez-icon
                              class="event-card__tooltip"
                              name="question-circle"
                              color="#3d82cf"
                              size="14"
                            ></ez-icon>
                            <div class="event-card__title">Thank You Page</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="event-group">
                      <div class="event-group__title">Webinar</div>
                      <div class="event-group__card-container">
                        <div class="event-card">
                          <div class="event-card__image"></div>
                          <div class="event-card__title-wrap">
                            <ez-icon
                              class="event-card__tooltip"
                              name="question-circle"
                              color="#3d82cf"
                              size="14"
                            ></ez-icon>
                            <div class="event-card__title">Optin Page</div>
                          </div>
                        </div>

                        <div class="event-card">
                          <div class="event-card__image"></div>
                          <div class="event-card__title-wrap">
                            <ez-icon
                              class="event-card__tooltip"
                              name="question-circle"
                              color="#3d82cf"
                              size="14"
                            ></ez-icon>
                            <div class="event-card__title">Thank You Page</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ez-scrollable>
          </div>

          <template slot="footer">
            <ez-button-group justify="end">
              <ez-button type="primary" @click="funnelMap = false"
                >Understood, thanks</ez-button
              >
            </ez-button-group>
          </template>
        </ez-dialog>

        <!-- DIALOG SHARE FUNNEL -->
        <ez-dialog :open.sync="shareFunnel" size="xmedium" icon-header="share">
          <template slot="header"
            >Share Funnel</template
          >

          <div class="share-info mb-3">
            <ez-icon
              class="share-info__icon"
              name="share-alt"
              ratio="80"
              size="40"
              color="#555555"
            ></ez-icon>
            <div class="share-info__wrap">
              <div class="share-info__title">Share your funnel with others</div>
              <div class="share-info__description">
                Share this URL with any EZFunnels user to give them an exact
                copy of your funnel
              </div>
            </div>
          </div>

          <ez-button-group>
            <ez-input
              value="https://ezfunnels.com/funnel/12345/share/09342hkjh4"
            ></ez-input>
            <ez-button icon="ellipsis-h"></ez-button>
          </ez-button-group>
        </ez-dialog>
      </ez-main>
    </ez-content>
  </div>
</template>

<style>
  .pointer {
    cursor: pointer;
  }

  .handle {
    cursor: move;
  }
</style>
