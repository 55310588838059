<script>
import draggable from 'vuedraggable';
import { mapGetters, createNamespacedHelpers } from 'vuex';
import { mapFields } from 'vuex-map-fields';
import { OPTIONS } from '@/enums';
import editGroupDialog from '@/components/dialogs/changeGroup';
import confirmDelete from '@/components/dialogs/confirmDelete';
import ezLoadingBlock from '@/components/ezLoadingBlock.vue';
import EzfEmpty from '@/components/empty/empty.vue';
import funnelsManager from '@/services/funnelsManager';

import { GROUPS, COUNT_GROUPS } from '@/store/funnels/getters';
import { SET_ACTIVE_NAV_TAB } from '@/store/header/mutations';
import * as funnelsActions from '@/store/funnels/actions';
import EzfGroupCreateButton from './group-create-button.vue';

const { mapMutations: headerMapMutations } = createNamespacedHelpers('header');
const {
  mapGetters: funnelMapGetters,
  mapActions: funnelMapActions,
} = createNamespacedHelpers('funnels');

export default {
  name: 'EzfFunnelsGroups',
  components: {
    draggable,
    ezLoadingBlock,
    EzfEmpty,
    EzfGroupCreateButton,
  },
  data() {
    return {
      isLoading: false,
      color: OPTIONS.GROUPS_COLOR,
      countUngroupedFunnels: 0,
    };
  },
  computed: {
    ...mapFields('funnels', ['search']),
    ...mapGetters(['currentUserId']),
    ...funnelMapGetters([GROUPS]),
    ...funnelMapGetters({ count: COUNT_GROUPS }),
    showUngroupedFunnels() {
      return this.countUngroupedFunnels > 0;
    },
    groups: {
      get() {
        return this[GROUPS];
      },
      set(value) {
        this.reorderGroups(value);
      },
    },
    cells() {
      return this.groups.reduce((result, group) => {
        result[group.id] = this.cellsTemplate(group.funnelCount);
        return result;
      }, {});
    },
  },
  methods: {
    ...headerMapMutations({ setActiveNavTab: SET_ACTIVE_NAV_TAB }),
    ...funnelMapActions({
      loadGroups: funnelsActions.LOAD_GROUPS,
      updateGroup: funnelsActions.UPDATE_GROUP,
      deleteGroup: funnelsActions.DELETE_GROUP,
      reorderGroups: funnelsActions.REORDER_GROUPS,
    }),
    async onEditGroup(index) {
      const group = this.groups[index];
      const result = await editGroupDialog(group);
      if (result) {
        const params = {
          id: group.id,
          values: result,
        };
        this.updateGroup(params);
      }
    },
    async loadData() {
      this.isLoading = true;
      await this.loadGroups();
      this.countUngroupedFunnels = await funnelsManager.getUngroupedCount(this.currentUserId);
      this.isLoading = false;
    },
    async onDeleteGroup(index) {
      const group = this.groups[index];
      const isDelete = await confirmDelete('group', group.name);
      if (isDelete) {
        await this.deleteGroup(group.id);
        this.countUngroupedFunnels += group.funnelCount;
      }
    },
    cellsTemplate(count) {
      return [{ title: this.$tc('groups.funnels', count), value: count }];
    },
  },
  created() {
    this.setActiveNavTab(this.$route.name);
    this.search = this.$route.query.search || '';
    this.loadData();
  },
  watch: {
    search(search) {
      const { search: searchParam = '' } = this.$route.query;
      if (search !== searchParam) {
        this.$router.replace({ query: search && { search } });
      }
    },
  },
};
</script>

<template>
  <div class="content-wrapper">
    <ez-sub-header>
      <template slot="title">
        <i18n path="showMe.text" tag="div">
          <span>{{ $t("showMe.groups") }}</span>
        </i18n>
      </template>

      <!--
      <template slot="filters">
        <ez-button icon="plus">Add Filters</ez-button>
      </template>
      -->

      <template slot="search">
        <ez-form-item direction="end">
          <ez-input
            type="search"
            v-model="search"
            :placeholder="$tc('groups.search.placeholder', count)"
          ></ez-input>
        </ez-form-item>
      </template>
    </ez-sub-header>

    <ez-content background>
      <ez-main>
        <ez-container>
          <ez-loading-block :loading="isLoading">
            <ezf-empty
              data-test="groups-empty"
              v-if="groups.length === 0"
              type="groups"
            >
              <template slot="title">
                {{ $t("groups.empty.title") }}
              </template>
              <template slot="description">
                {{ $t("groups.empty.description") }}
              </template>
              <ez-button-group justify="center">
                <ezf-group-create-button />
              </ez-button-group>
            </ezf-empty>
            <ez-row class="mb-4" v-else>
              <ez-col>
                <draggable v-model="groups" draggable=".isDrag">
                  <ez-panel
                    v-for="(group, index) in groups"
                    :key="group.id"
                    :colorArea="group.color || 'rgba(255, 255, 255, 0)'"
                    :cells="cells[group.id]"
                    size="medium"
                    :class="{ isDrag: search === '' }"
                    :data-test="`group-${group.id}`"
                  >
                    <template slot="title">{{ group.name }}</template>

                    <template slot="control">
                      <ez-dropdown type="solid">
                        <ez-button
                          class="dots-button"
                          type="text"
                          icon="ellipsis-v"
                        ></ez-button>
                        <ez-dropdown-menu slot="menu" direction="right">
                          <ez-dropdown-item
                            icon="pen"
                            @click="onEditGroup(index)"
                          >
                            {{ $t("Edit") }}
                          </ez-dropdown-item>
                          <ez-dropdown-item
                            icon="trash"
                            class="color-danger"
                            @click="onDeleteGroup(index)"
                          >
                            {{ $t("Delete") }}
                          </ez-dropdown-item>
                        </ez-dropdown-menu>
                      </ez-dropdown>
                    </template>
                  </ez-panel>

                  <ez-panel
                    v-if="showUngroupedFunnels"
                    slot="footer"
                    size="medium"
                    :colorArea="color || 'rgba(255, 255, 255, 0)'"
                    :cells="cellsTemplate(countUngroupedFunnels)"
                  >
                    <template slot="title">
                      {{ $t("groups.ungroupedFunnels") }}
                    </template>
                    <template slot="control">
                      <ez-button style="visibility: hidden;"></ez-button>
                    </template>
                  </ez-panel>
                </draggable>
              </ez-col>
            </ez-row>
          </ez-loading-block>
        </ez-container>
      </ez-main>
    </ez-content>
  </div>
</template>
