<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import { slugify } from 'transliteration';
import ezfSelectDomain from '@/components/select-domain.vue';
import ezfSelectGroup from '@/components/select-group.vue';
import { CLONE_FUNNEL } from '@/store/funnel/actions.js';
import { NAME } from '.';

const { mapActions } = createNamespacedHelpers('funnel');

export default {
  name: 'EzfEditTemplateDialog',
  inject: ['$validator'],
  $_veeValidate: {
    validator: 'new',
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    domainId: {
      type: String,
    },
    groupId: {
      type: String,
    },
  },
  components: {
    ezfSelectDomain,
    ezfSelectGroup,
  },
  data() {
    const { name } = this;
    return {
      saving: false,
      form: {
        name: this.$t('funnel.clone.newName', { name }),
        slug: '',
        domainId: this.domainId,
        groupId: this.groupId,
      },
    };
  },
  computed: {
    ...mapState(['mainUser']),
  },
  methods: {
    ...mapActions([CLONE_FUNNEL]),
    close() {
      this.$modal.hide(NAME);
    },
    slugify(value) {
      return slugify(value, { allowedChars: 'a-zA-Z0-9_' });
    },
    slugInput({ target }) {
      const { value, selectionStart, selectionEnd } = target;
      const slug = this.slugify(`${value}a`).slice(0, -1); // hack with "a" to allow enter "-" to end
      target.value = slug;
      const { length } = slug;
      target.selectionStart = Math.min(length, selectionStart);
      target.selectionEnd = Math.min(length, selectionEnd);
      this.form.slug = slug;
    },
    async submit() {
      const isValid = await this.$validator.validate();
      if (!isValid) {
        return;
      }
      try {
        this.saving = true;
        const { form } = this;
        const result = await this[CLONE_FUNNEL]({
          id: this.id,
          attributes: form,
        });
        this.$modal.hide(NAME, result);
        this.$notify({
          data: {
            type: 'success',
            content: this.$t('funnel.clone.notify.succes'),
          },
        });
      } catch (e) {
        // this.$notify({
        //   data: {
        //     type: 'error',
        //     content: e.message || e,
        //   },
        // });
        // show /data/attribute/* errors
        for (const error of e.response.data.errors) {
          const { pointer } = error.source;
          const PREFIX = '/data/attribute/';
          let msg = error.detail || error.title;
          msg = this.$t(`funnel.clone.errors.${msg}`) || msg;
          if (pointer.startsWith(PREFIX)) {
            let field = pointer.substr(PREFIX.length);
            if (field === 'builder') {
              field = 'template-id';
            }

            this.errors.add({
              field,
              msg,
            });

            // this.$notify({
            //   data: {
            //     type: 'error',
            //     content: `${this.$t(`funnel.clone.errors.${field}`) || field}: ${msg}`,
            //   },
            // });
          } else {
            this.$notify({
              data: {
                type: 'error',
                content: `${pointer}: ${msg}`,
              },
            });
          }
        }
      } finally {
        this.saving = false;
      }
    },
  },
  mounted() {
    if (!this.isEdit) {
      const { name } = this.$refs.form.$el;
      name.select();
      name.focus();
    }
  },
  watch: {
    'form.name': {
      handler(value, oldValue) {
        const currentSlug = this.slugify(this.form.slug);
        if (currentSlug === '' || currentSlug === this.slugify(oldValue)) {
          this.form.slug = this.slugify(value);
        }
      },
      immediate: true,
    },
  },
};
</script>

<template>
  <ez-form ref="form" @submit.prevent="submit">
    <ez-dialog-header icon="clone">
      {{ $t("funnel.clone.dialogTitle") }}
    </ez-dialog-header>

    <ez-dialog-body @close="close">
      <ez-form-item
        :label="$t('builder.page.dialog.nameLabel')"
        :type="errors.has('name') ? 'error' : null"
        :message="errors.first('name')"
      >
        <ez-input
          name="name"
          :placeholder="$t('builder.step.add.name.placeholder')"
          v-model="form.name"
          v-validate="'required'"
        />
      </ez-form-item>

      <ez-form-group>
        <ez-row gutter="16">
          <ez-col size="12">
            <ezf-select-domain v-model="form.domainId" :user="mainUser" />
          </ez-col>
          <ez-col size="12">
            <ez-form-item
              :label="$t('builder.page.dialog.slugLabel')"
              :type="errors.has('slug') ? 'error' : null"
              :message="errors.first('slug')"
            >
              <ez-input
                name="slug"
                v-model="form.slug"
                @input.native="slugInput"
                v-validate="{
                  required: true,
                  regex: /^[-a-z0-9_]+$/
                }"
              />
            </ez-form-item>
          </ez-col>
        </ez-row>
      </ez-form-group>

      <ezf-select-group v-model="form.groupId" />
    </ez-dialog-body>

    <ez-dialog-footer>
      <ez-button-group justify="end">
        <ez-button type="secondary" @click="close">
          {{ $t("builder.buttons.discard") }}
        </ez-button>
        <ez-button
          :disabled="saving"
          :preloader="saving"
          native-type="submit"
          type="success"
          @click.prevent="submit"
        >
          {{ $t("funnel.clone.submitButton") }}
        </ez-button>
      </ez-button-group>
    </ez-dialog-footer>
  </ez-form>
</template>
