<script>
import EzEmptyPage from '@dmant/ez-ui-components/src/components/empty-page';

const images = require.context('@/assets/empty', false, /\.svg$/);
const defaultImage = './default.svg';

const keys = images.keys();

export default {
  name: 'EzfEmpty',
  components: { EzEmptyPage },
  props: {
    type: {
      type: String,
      default: 'default',
    },
    imgWidth: {
      type: String,
      default: '174',
    },
  },
  computed: {
    svg() {
      const key = `./${this.type}.svg`;
      return images(keys.includes(key) ? key : defaultImage);
    },
  },
};
</script>

<template>
  <div class="contacts-empty-page">
    <ez-empty-page>
      <template slot="image">
        <img :src="svg" :width="imgWidth" />
      </template>

      <slot name="title" slot="title" />
      <slot name="description" slot="description" />
    </ez-empty-page>
    <slot />
  </div>
</template>
