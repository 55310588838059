export default {
  methods: {
    formatValue(value) {
      return value.toString().replace(/\d(?=(\d{3})+(\.|$))/g, '$&,');
    },

    formatNumber(value) {
      return this.formatValue(Math.round(value));
    },

    formatCost(value) {
      const formated = this.formatValue(value.toFixed(2));
      return `${formated}\u00A0${this.currency.symbol}`;
    },

    cells(funnel) {
      const {
        uniqueViews, leads, sales, revenue,
      } = funnel;
      return [
        {
          title: this.$t('funnels.item.visitors'),
          value: this.formatNumber(uniqueViews),
        },
        {
          title: this.$t('funnels.item.leads'),
          value: this.formatNumber(leads),
        },
        {
          title: this.$t('funnels.item.sales'),
          value: this.formatNumber(sales),
        },
        {
          title: this.$t('funnels.item.revenue'),
          value: this.formatCost(revenue),
        },
      ];
    },
  },
};
