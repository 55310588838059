<script>
import { mapState, createNamespacedHelpers } from 'vuex';
import { OPTIONS } from '@/enums';
import showCloneFunnelDialog from '@/components/dialogs/cloneFunnel';
import { FUNNELS } from '@/store/funnels/getters';
import { SET_CHECK_FUNNEL, ADD_FUNNEL } from '@/store/funnels/mutations';
import { UPDATE_FUNNELS } from '@/store/funnels/actions';
import cellsMixin from './mixins/panelCells';

const { mapGetters, mapMutations, mapActions } = createNamespacedHelpers(
  'funnels',
);
export default {
  name: 'EzfGroupFunnels',
  mixins: [cellsMixin],

  props: {
    group: {
      type: Object,
      required: true,
    },
  },

  methods: {
    ...mapMutations([SET_CHECK_FUNNEL, ADD_FUNNEL]),
    ...mapActions([UPDATE_FUNNELS]),
    getColor(checked) {
      return checked ? OPTIONS.SELECT_COLOR : (this.group.color || 'rgba(255, 255, 255, 0)');
    },
    onCheckFunnel(checked, funnelId) {
      this[SET_CHECK_FUNNEL]({ checked, groupId: this.group.id, funnelId });
    },
    onClick(routeName, funnelId) {
      this.$router.push({
        name: routeName,
        params: { funnel_id: funnelId },
      });
    },
    async cloneFunnel(funnel) {
      const result = await showCloneFunnelDialog(funnel);
      if (result) {
        this[ADD_FUNNEL](result);
        // await this.loadData();
      }
    },
    onArchive(id) {
      this[UPDATE_FUNNELS]({
        ids: [id],
        values: { archive: true },
      });
    },
  },

  computed: {
    ...mapState(['currency']),
    ...mapGetters([FUNNELS]),
    funnels() {
      const { id } = this.group;
      return this[FUNNELS].filter((f) => f.groupId === id);
    },
  },
};
</script>

<template>
  <ez-row class="pl-5">
    <ez-col>
      <ez-panel
        v-for="funnel in funnels"
        :key="funnel.id"
        size="medium"
        :colorArea="getColor(funnel.checked)"
        :cells="cells(funnel)"
      >
        <template slot="checkbox">
          <ez-checkbox
            :value="funnel.checked"
            @input="onCheckFunnel($event, funnel.id)"
          ></ez-checkbox>
          <!-- <ez-icon
            name="drag-dots"
            size="12"
            class="handle"
            color="rgba(255, 255, 255, 0.4)"
          /> -->
        </template>

        <template slot="title">
          {{ funnel.name }}
          <div class="ez-panel__table">
            <div class="ez-panel__table-row">
              <div class="ez-panel__table-cell">
                <div
                  class="ez-panel__cell-title"
                  v-t="'funnels.item.funnelType:'"
                ></div>
              </div>
              <div class="ez-panel__table-cell">
                <div
                  class="ez-panel__cell-title"
                  v-t="`funnels.types.${funnel.type}`"
                ></div>
              </div>
            </div>
            <div class="ez-panel__table-row">
              <div class="ez-panel__table-cell">
                <div
                  class="ez-panel__cell-title"
                  v-t="'funnels.item.lastEdited'"
                ></div>
              </div>
              <div class="ez-panel__table-cell">
                <div class="ez-panel__cell-title">
                  {{ funnel.dtUpdateAgo }}
                </div>
              </div>
            </div>
          </div>
        </template>

        <template slot="buttons">
          <ez-button-group>
            <ez-button
              type="success"
              @click="onClick('funnelBuilder', funnel.id)"
              v-t="'funnels.item.buttons.edit'"
            />
            <ez-button
              type="default"
              outline
              icon="users"
              @click="onClick('funnelContacts', funnel.id)"
              :title="$t('funnels.item.buttons.contacts')"
            />
            <ez-button
              type="default"
              outline
              icon="chart-pie"
              @click="onClick('funnelStatistics', funnel.id)"
              :title="$t('funnels.item.buttons.statistics')"
            />
          </ez-button-group>
        </template>

        <template slot="control">
          <ez-dropdown type="solid">
            <ez-button
              class="dots-button"
              type="text"
              icon="ellipsis-v"
            ></ez-button>
            <ez-dropdown-menu slot="menu" direction="right">
              <!-- <ez-dropdown-item icon="eye" @click="funnelMap = true">
                {{ $t("funnels.item.….showFunnelmap") }}
              </ez-dropdown-item> -->
              <ez-dropdown-item icon="clone" @click="cloneFunnel(funnel)">
                {{ $t("funnels.item.….clone") }}
              </ez-dropdown-item>
              <!-- <ez-dropdown-item icon="share">
                {{ $t("funnels.item.….share") }}
              </ez-dropdown-item> -->
              <ez-dropdown-item icon="archive" @click="onArchive(funnel.id)">
                {{ $t("funnels.item.….archive") }}
              </ez-dropdown-item>
              <ez-dropdown-item
                icon="cogs"
                @click="onClick('funnelSettings', funnel.id)"
              >
                {{ $t("funnels.item.….settings") }}
              </ez-dropdown-item>
            </ez-dropdown-menu>
          </ez-dropdown>
        </template>
      </ez-panel>
    </ez-col>
  </ez-row>
</template>
