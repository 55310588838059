<script>
export default {
  name: 'EzfLoadingBlock',
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<template>
  <div>
    <slot v-if="!loading" />
    <ez-row v-else>
      <ez-preloader
        :value="loading"
        :stop="false"
        style="position: relative; margin: 40px 0;"
      />
    </ez-row>
  </div>
</template>
